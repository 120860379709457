<template>
  <b-row>
    <b-col sm="12">
      <analytics-congratulation :data="user" />

      <div />
    </b-col>
  </b-row></template>

<script>
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'

export default {
  components: {
    AnalyticsCongratulation,
  },
  data() {
    return {
      currentUser: this.$store.getters.currentUser,
      user: {
        name: `${this.$store.getters.currentUser.last_name} ${this.$store.getters.currentUser.first_name}`,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
